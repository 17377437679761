const version = "v.1.0.0";
const appURL = "http://localhost:3002/api/";
//const appURL = "https://app.inspectiv.saft.industries/api/";
const authToken = localStorage.getItem("Auth-Token");

let userInfo = {};

const ERR_MSG_ADMIN_ONLY = "Esta función está disponible sólo para usuarios Administradores.";
const ERR_MSG_UNEXPECTED = "Ocurrió un error inesperado. Por favor, reporta al email de soporte la información que se muestra en pantalla: ";
const ERR_MSG_MISSING_FIELDS = "Algunos campos requeridos están incompletos.";
const ERR_MSG_SUPERVISOR_ONLY = "Esta función está disponible sólo para usuarios Supervisores/Administradores.";
const ERR_MSG_VALUE_OUT_OF_BOUNDS = "Un campo tiene un valor fuera del rango permitido";
const ERR_MSG_USER_REQUIRED = "Se requiere seleccionar un usuario.";
const ERR_MSG_ADDRESS_NOT_FOUND = "No se encontró la dirección";
const ERR_MSG_NOT_AVAILABLE_SHORT = "No disponible";

const MILLSECONDS_IN_DAY = 86400000;
const MAX_DAYS_ALLOWED_TO_START_ACTIVITY = 30;

const errorArea = document.getElementById("errorArea");
const notificationsArea = document.getElementById("notificationsArea");
const spanVersion = document.getElementById("spanVersion");

function isoDateToLocal(isoDate) {
	let parts = isoDate.split("-");
	return new Date(parts[0], parts[1] - 1, parts[2]).getTime();
}

function formatDateForInput(date) {
	let options = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];

	function format(option) {
	   let formatter = new Intl.DateTimeFormat('en', option);
	   return formatter.format(date);
	}
	return options.map(format).join('-');
}

function showError(msg) {
	notificationsArea.hidden = true;
	errorArea.innerText = msg;
}

function clearError(msg) {
	notificationsArea.hidden = false;

	if (!msg)
		notificationsArea.innerText = "Todo en orden :)";
	else 
		notificationsArea.innerText = msg;

	errorArea.innerText = "";	
}

async function postData(url = "", data = {}) {
	// Default options are marked with *
	const response = await fetch(appURL + url, {
	  method: "POST", // *GET, POST, PUT, DELETE, etc.
	  mode: "cors", // no-cors, *cors, same-origin
	  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: "include", // include, *same-origin, omit
	  headers: {
		"Content-Type": "application/json",
		"Access-Control-Request-Headers": "Auth-Token",
		"Auth-Token": authToken
	  },
	  redirect: "follow", // manual, *follow, error
	  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	  body: JSON.stringify(data), // body data type must match "Content-Type" header
	});

	console.log(response.status);
	let answer = await response.json();
	console.log(answer);

	return answer; // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {

	const queryString = appURL + url + "?" + new URLSearchParams(data).toString();
	console.log(queryString);

	// Default options are marked with *
	const response = await fetch(queryString, {
	  method: "GET", // *GET, POST, PUT, DELETE, etc.
	  mode: "cors", // no-cors, *cors, same-origin
	  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: "include", // include, *same-origin, omit
	  headers: {
		"Content-Type": "application/json",
		"Access-Control-Request-Headers": "Auth-Token",
		"Auth-Token": authToken
	  },
	  redirect: "follow", // manual, *follow, error
	  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	});

	console.log(response.status);
	let answer = await response.json();
	console.log(answer);

	return answer; // parses JSON response into native JavaScript objects
}

async function getImage(url = "", data = {}) {

	const queryString = appURL + url + "?" + new URLSearchParams(data).toString();
	console.log(queryString);

	// Default options are marked with *
	const response = await fetch(queryString, {
	  method: "GET", // *GET, POST, PUT, DELETE, etc.
	  mode: "cors", // no-cors, *cors, same-origin
	  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
	  credentials: "include", // include, *same-origin, omit
	  headers: {
		"Content-Type": "image/jpg",
		"Access-Control-Request-Headers": "Auth-Token",
		"Auth-Token": authToken
	  },
	  redirect: "follow", // manual, *follow, error
	  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	});

	console.log(response.status);
	let answer = await response.blob();

	return answer; // parses JSON response into native JavaScript objects
}

async function checkUser() {

	try {

		userInfo = await getData("check-user", {});

		if (userInfo.status == "err") {
			location = "login.html";
			return;
		}

		const spanFirstName = document.getElementById("spanFirstName");
		if (spanFirstName) spanFirstName.innerText = userInfo.data.first_name;

		const linkProfileEmail = document.getElementById("linkProfileEmail");
		if (linkProfileEmail) {
			linkProfileEmail.innerHTML += userInfo.data.email_username;
			linkProfileEmail.onClick = () => {window.event.preventDefault();};
		}

	} catch (err) {
		alert("Ocurrió un error. Favor de intentar más tarde.");
	}

}

async function logout() {
	window.event.preventDefault();
	
	try {

		await getData("logout", {});
		localStorage.removeItem("Auth-Token");
		location = "login.html";

	} catch (err) {
		alert("Ocurrió un error. Favor de intentar más tarde.");
	}

}

const linkLogout = document.getElementById("linkLogout");
if (linkLogout) linkLogout.onclick = logout;

spanVersion.innerText = version;
